import Vue from 'vue'
import VueRouter from 'vue-router'
import {
	Message,
	MessageBox
} from 'element-ui'
Vue.use(VueRouter)
// 解决重复点击相同路由报错
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
const routes = [

	{
		path: "/",
		name: "登录页",
		component: () => import('@/views/Login/index.vue')
	},
	{
		path: "/homeParents",
		name: "首页-家长",
		component: () => import('@/views/parents/Index.vue')
	},
	{
		path: "/index_student_listParents",
		name: "学生管理-家长",
		component: () => import('@/views/parents/index_student_list.vue')
	},
	{
		path: "/index_teacher_listParents",
		name: "教师管理-家长",
		component: () => import('@/views/parents/index_teacher_list.vue')
	},
	{
		path: "/comprehensiveParents",
		name: "综合测评-家长",
		component: () => import('@/views/parents/comprehensive/comprehensive.vue')
	},
	{
		path: "/com_answer_agoParents",
		name: "综合测评-答题-家长",
		component: () => import('@/views/parents/comprehensive/com_answer_ago.vue')
	},
	{
		path: "/encyclopaediaParents",
		name: "知能百科-家长",
		component: () => import('@/views/parents/encyclopaedia/encyclopaedia.vue')
	},
	{
		path: "/encyclopaedia_audioParents",
		name: "知能百科-音频-家长",
		component: () => import('@/views/parents/encyclopaedia/encyclopaedia_audio.vue')
	},
	{
		path: "/encyclopaedia_videoParents",
		name: "知能百科-视频-家长",
		component: () => import('@/views/parents/encyclopaedia/encyclopaedia_video.vue')
	},
	{
		path: "/encyclopaedia_articleParents",
		name: "知能百科-文章-家长",
		component: () => import('@/views/parents/encyclopaedia/encyclopaedia_article.vue')
	},
	{
		path: "/account_informationParents",
		name: "个人中心-账户信息-家长",
		component: () => import('@/views/parents/personal_center/account_information.vue')
	},
	{
		path: "/personal_centerParents",
		name: "个人中心-个人信息-家长",
		component: () => import('@/views/parents/personal_center/personal_center.vue')
	},
	{
		path: "/my_collectParents",
		name: "个人中心-个人信息-家长",
		component: () => import('@/views/parents/personal_center/my_collect.vue')
	},
	{
		path: "/message_center_parents",
		name: "消息中心-家长",
		component: () => import('@/views/parents/message_center.vue')
	},
	{
		path: "/appointment_detailsParents",
		name: "预约列表-预约详情-家长",
		component: () => import('@/views/parents/appointment_details.vue')
	},
	{
		path: "/com_answer_cntreParents",
		name: "综合测评-答题中-家长",
		component: () => import('@/views/parents/comprehensive/com_answer_centre.vue')
	},
	{
		path: "/com_answer_laterParents",
		name: "综合测评-答题后-家长",
		component: () => import('@/views/parents/comprehensive/com_answer_later.vue')
	},
	{
		path: "/com_answer_resultParents",
		name: "综合测评-答题后-家长",
		component: () => import('@/views/parents/comprehensive/com_answer_result.vue')
	},
	{
		path: "/com_answer_down",
		name: "综合测评-报告",
		component: () => import('@/views/parents/comprehensive/com_answer_dwon.vue')
	},


	// 学习中心
	{
		path: "/studyParents",
		name: "学习中心-家长",
		component: () => import('@/views/parents/studyCentre/study.vue')
	},
	{
		path: "/study_listParents",
		name: "学习中心-学习课程列表-家长",
		component: () => import('@/views/parents/studyCentre/study_list.vue')
	},

	// 调查问卷
	{
		path: "/questionnaireParents",
		name: "调查问卷-家长",
		component: () => import('@/views/parents/questionnaire/questionnaire.vue')
	},
	{
		path: "/questions_answerParents",
		name: "调查问卷-问卷调查答题-家长",
		component: () => import('@/views/parents/questionnaire/questions_answer.vue')
	},
	{
		path: "/questions_recordParents",
		name: "调查问卷-问卷调查答题-家长",
		component: () => import('@/views/parents/questionnaire/questions_record.vue')
	},
	{
		path: "/qurestions_succeedParents",
		name: "调查问卷-问卷调查答题成功",
		component: () => import('@/views/parents/questionnaire/qurestions_succeed.vue')
	},


	// 家访
	{
		path: "/homeVisitingParents",
		name: "家访预约列表-家长",
		component: () => import('@/views/parents/homeVisiting/homeVisiting.vue')
	},
	// 活动
	{
		path: "/activityParents",
		name: "活动列表-家长",
		component: () => import('@/views/parents/activity/activity.vue')
	},
	// 心理
	{
		path: "/psychologyParents",
		name: "心理咨询-家长",
		component: () => import('@/views/parents/psychology/psychology.vue')
	},
	{
		path: "/PsychologicalAppParents",
		name: "预约心理咨询-家长",
		component: () => import('@/views/parents/psychology/PsychologicalApp.vue')
	},
	{
		path: "/PsychologicalAppParentsDetail",
		name: "预约心理咨询详情-家长",
		component: () => import('@/views/parents/psychology/PsychologicalAppDetail.vue')
	},
	{
		path: "/teacherDetailParents",
		name: "老师详情-家长",
		component: () => import('@/views/parents/psychology/teacherDetail.vue')
	},
	{
		path: "/PsychologicalParentsSuccess",
		name: "预约心理咨询成功-家长",
		component: () => import('@/views/parents/psychology/PsychologicalSuccess.vue')
	},
	{
		path: "/Indexstudents",
		name: "首页-学生",
		component: () => import('@/views/students/Index.vue')
	},
	{
		path: "/fieldRooms",
		name: "场室预约-学生",
		component: () => import('@/views/students/fieldRoom/fieldRoom.vue'),
	},
	{
		path: "/studentConsult",
		name: "心理咨询",
		component: () => import('@/views/students/consult/Index.vue')
	},
	{
		path: "/consultEnter",
		name: "心理咨询",
		component: () => import('@/views/students/consult/evaluation.vue')
	},
	{
		path: "/consultCheck",
		name: "心理咨询",
		component: () => import('@/views/students/consult/EvaluationCheck.vue')
	},
	{
		path: "/consultDetail",
		name: "心理咨询",
		component: () => import('@/views/students/consult/details.vue')
	},
	{
		path: "/answerComplete",
		name: "心理咨询",
		component: () => import('@/views/students/consult/answerComplete.vue')
	},
	{
		path: "/consultfeedback",
		name: "咨询反馈",
		component: () => import('@/views/students/consult/feedback.vue')
	},
	{
		path: "/feedbackDetails",
		name: "反馈详情",
		component: () => import('@/views/students/consult/feedbackDetails.vue')
	},
	{
		path: "/index_student_liststudents",
		name: "学生管理-学生",
		component: () => import('@/views/students/index_student_list.vue')
	},
	{
		path: "/comprehensivestudents",
		name: "综合测评-学生",
		component: () => import('@/views/students/comprehensive/comprehensive.vue')
	},
	{
		path: "/com_answer_agostudents",
		name: "综合测评-答题-学生",
		component: () => import('@/views/students/comprehensive/com_answer_ago.vue')
	},
	{
		path: "/encyclopaediastudents",
		name: "知能百科-学生",
		component: () => import('@/views/students/encyclopaedia/encyclopaedia.vue')
	},
	{
		path: "/encyclopaedia_audiostudents",
		name: "知能百科-音频-学生",
		component: () => import('@/views/students/encyclopaedia/encyclopaedia_audio.vue')
	},
	{
		path: "/encyclopaedia_videostudents",
		name: "知能百科-视频-学生",
		component: () => import('@/views/students/encyclopaedia/encyclopaedia_video.vue')
	},
	{
		path: "/encyclopaedia_articlestudents",
		name: "知能百科-文章-学生",
		component: () => import('@/views/students/encyclopaedia/encyclopaedia_article.vue')
	},
	{
		path: "/account_informationstudents",
		name: "个人中心-账户信息-学生",
		component: () => import('@/views/students/personal_center/account_information.vue')
	},
	{
		path: "/personal_centerstudents",
		name: "个人中心-个人信息-学生",
		component: () => import('@/views/students/personal_center/personal_center.vue')
	},
	{
		path: "/my_collectstudents",
		name: "个人中心-个人信息-学生",
		component: () => import('@/views/students/personal_center/my_collect.vue')
	},
	{
		path: "/message_center_students",
		name: "消息中心-学生",
		component: () => import('@/views/students/message_center.vue')
	},
	{
		path: "/index_teacher_list_students",
		name: "教师管理-学生",
		component: () => import('@/views/students/index_teacher_list.vue')
	},
	{
		path: "/appointment_detailsstudents",
		name: "预约列表-预约详情-学生",
		component: () => import('@/views/students/appointment_details.vue')
	},
	// push之前删掉
	{
		path: "/com_answer_cntrestudents",
		name: "综合测评-答题中-学生",
		component: () => import('@/views/students/comprehensive/com_answer_centre.vue')
	},
	{
		path: "/com_answer_laterstudents",
		name: "综合测评-答题后-学生",
		component: () => import('@/views/students/comprehensive/com_answer_later.vue')
	},
	{
		path: "/com_answer_resultstudents",
		name: "综合测评-答题后-学生",
		component: () => import('@/views/students/comprehensive/com_answer_result.vue')
	},
	{
		path: "/com_answer_downstudents",
		name: "综合测评-报告",
		component: () => import('@/views/students/comprehensive/com_answer_dwon.vue')
	},

	// 学习中心
	{
		path: "/studystudents",
		name: "学习中心-学生",
		component: () => import('@/views/students/studyCentre/study.vue')
	},
	{
		path: "/study_liststudents",
		name: "学习中心-学习课程列表-学生",
		component: () => import('@/views/students/studyCentre/study_list.vue')
	},

	// 调查问卷
	{
		path: "/questionnairestudents",
		name: "调查问卷-学生",
		component: () => import('@/views/students/questionnaire/questionnaire.vue')
	},
	{
		path: "/questions_answerstudents",
		name: "调查问卷-问卷调查答题-学生",
		component: () => import('@/views/students/questionnaire/questions_answer.vue')
	},
	{
		path: "/questions_recordstudents",
		name: "调查问卷-问卷调查答题-学生",
		component: () => import('@/views/students/questionnaire/questions_record.vue')
	},
	{
		path: "/qurestions_succeedstudents",
		name: "调查问卷-问卷调查答题成功-学生",
		component: () => import('@/views/students/questionnaire/qurestions_succeed.vue')
	},


	// 家访
	{
		path: "/homeVisitingstudents",
		name: "家访预约列表-学生",
		component: () => import('@/views/students/homeVisiting/homeVisiting.vue')
	},
	// 活动
	{
		path: "/activitystudents",
		name: "活动列表-学生",
		component: () => import('@/views/students/activity/activity.vue')
	},
	// 心理
	{
		path: "/psychologystudents",
		name: "心理咨询-学生",
		component: () => import('@/views/students/psychology/psychology.vue')
	},
	{
		path: "/psychologystudents_teacherDetail",
		name: "心理咨询-教师详情-学生",
		component: () => import('@/views/students/psychology/teacherDetail.vue')
	},
	{
		path: "/psychologystudents_success",
		name: "心理咨询-预约成功-学生",
		component: () => import('@/views/students/psychology/PsychologicalSuccess.vue')
	},
	{
		path: "/PsychologicalAppstudents",
		name: "预约心理咨询列表-学生",
		component: () => import('@/views/students/psychology/PsychologicalApp.vue')
	},
	{
		path: "/singlePsycholostudents",
		name: "预约详情页面-学生",
		component: () => import('@/views/students/psychology/singlePsycholo.vue')
	},
	// 头环学生
	{
		path: '/headband',
		name: "头环结果-学生",
		component: () => import('@/views/students/headband/headband.vue')
	},
	{
		path: '/brain',
		name: "多模态-学生",
		component: () => import('@/views/students/multimodal/brain.vue')
	},
	// 老师
	{
		path: "/teacheHome",
		name: "教师首页",
		component: () => import('@/views/teacher/home.vue')
	},
	// 培训考试 
	{
		path: "/teacher_examination",
		name: "培训考试",
		component: () => import('@/views/teacher/examination/index.vue'),
		redirect: "teacher_examination/course",
		children: [{
				path: "course",
				name: "培训课程",
				component: () => import('@/views/teacher/examination/course.vue')
			},
			{
				path: "course_message",
				name: "测评任务",
				component: () => import('@/views/teacher/examination/train/message.vue')
			},
			{
				path: "confirm_order",
				name: "确认订单信息",
				component: () => import('@/views/teacher/examination/train/confirmOrder.vue')
			},
			{
				path: "test",
				name: "培训考试",
				component: () => import('@/views/teacher/examination/test.vue')
			},
			{
				path: "test_message",
				name: "测评任务",
				component: () => import('@/views/teacher/examination/test/message.vue')
			},
			{
				path: "test_making",
				name: "考试答题",
				component: () => import('@/views/teacher/examination/test/testMaking.vue')
			},
			{
				path: "/examVideo",
				name: "视频",
				component: () => import('@/views/teacher/examination/train/courseVideo.vue')
			},
		]
	},
	{
		path: "/Evaluation",
		name: "测评列表",
		component: () => import('@/views/teacher/Evaluation/EvaluationList.vue')
	},
	{
		path: "/EvaluationText",
		name: "测评任务",
		component: () => import('@/views/teacher/Evaluation/EvaluationText.vue')
	},
	{
		path: "/check",
		name: "测评详情",
		component: () => import('@/views/teacher/Evaluation/EvaluationCheck.vue')
	},
	{
		path: "/Enter",
		name: "测评详情入口",
		component: () => import('@/views/teacher/Evaluation/EvaluationEnter.vue')
	},
	{
		path: "/End",
		name: "测评管理",
		component: () => import('@/views/teacher/Evaluation/EvaluationEnd.vue')
	},
	{
		path: "/down",
		name: "评测报告",
		component: () => import('@/views/teacher/Evaluation/EvaluationDwon.vue')
	},
	{
		path: "/studentManage",
		name: "教师首页-学生管理",
		component: () => import('@/views/teacher/Evaluation/studentManage.vue')
	},
	{
		path: "/account_informationteacher",
		name: "个人中心-账户信息-教师",
		component: () => import('@/views/teacher/personal_center/account_information.vue')
	},
	{
		path: "/personal_centerteacher",
		name: "个人中心-个人信息-教师",
		component: () => import('@/views/teacher/personal_center/personal_center.vue')
	},
	{
		path: "/my_collectteacher",
		name: "个人中心-个人信息-教师",
		component: () => import('@/views/teacher/personal_center/my_collect.vue')
	},
	// 教师活动
	{
		path: "/activityTeacher",
		name: "活动列表-教师",
		component: () => import('@/views/teacher/activity/activity.vue')
	},
	{
		path: "/activityListTeacher",
		name: "活动任务-教师",
		component: () => import('@/views/teacher/activity/activityList.vue')
	},
	{
		path: "/account_informationTeacher",
		name: "个人中心-账户信息-教师",
		component: () => import('@/views/teacher/personal_center/account_information.vue')
	},
	{
		path: "/trainingFileTeacher",
		name: "个人中心-培训档案-教师",
		component: () => import('@/views/teacher/personal_center/trainingFile.vue')
	},
	{
		path: "/examinationFileTeacher",
		name: "个人中心-考试档案-教师",
		component: () => import('@/views/teacher/personal_center/examinationFile.vue')
	},
	{
		path: "/evaluationFileTeacher",
		name: "个人中心-测试档案-教师",
		component: () => import('@/views/teacher/personal_center/evaluationFile.vue')
	},
	{
		path: "/my_collectTeacher",
		name: "个人中心-我的收藏-教师",
		component: () => import('@/views/teacher/personal_center/my_collect.vue')
	},
	{
		path: "/ParentClassroom",
		name: "家校共育-家长课程",
		component: () => import('@/views/teacher/studentEduction/index.vue')
	},
	{
		path: "/communication",
		name: "家校共育-家长沟通记录",
		component: () => import('@/views/teacher/studentEduction/communication.vue')
	},
	{
		path: "/appointment",
		name: "家校共育-家访预约",
		component: () => import('@/views/teacher/studentEduction/appointment.vue')
	},
	{
		path: "/home_visit_wait_work",
		name: "家校共育-家访预约-家访待办",
		component: () => import('@/views/teacher/studentEduction/HomeVisitWaitWork/Index.vue')
	},
	// 督查
	{
		path: "/Manegement",
		name: "督查管理",
		component: () => import('@/views/teacher/supervision/index.vue')
	},
	//  咨询管理
  {
    path:"/consulting",
    name:"咨询管理-首页",
    component:()=>import('@/views/teacher/consulting/index.vue')
  },
  {
    path:"/consulting_set_time",
    name:"咨询管理-设置咨询时间",
    component:()=>import('@/views/teacher/consulting/ConsultingSetTime/Index.vue')
  },
  {
    path:"/consulting_wait_work",
    name:"咨询管理-咨询待办",
    component:()=>import('@/views/teacher/consulting/ConsultingWaitWork/Index.vue')
  },
	{
		path: "/fieldRoom",
		name: "场室预约-老师",
		component: () => import('@/views/teacher/fieldRoom/fieldRoom.vue')
	},
	{
		path: "/consult",
		name: "咨询预约-老师",
		component: () => import('@/views/teacher/consult/Index.vue')
	},
	{
		path: "/setTime",
		name: "咨询预约-老师",
		component: () => import('@/views/teacher/consult/setTime.vue')
	},
	{
		path: "/workWait",
		name: "咨询预约-老师",
		component: () => import('@/views/teacher/consult/workWait.vue')
	},
	{
		path: "/encyclopaediateacher",
		name: "知能百科-老师",
		component: () => import('@/views/teacher/encyclopaedia/encyclopaedia.vue')
	},
	{
		path: "/encyclopaedia_audioteacher",
		name: "知能百科-音频-老师",
		component: () => import('@/views/teacher/encyclopaedia/encyclopaedia_audio.vue')
	},
	{
		path: "/encyclopaedia_articleteacher",
		name: "知能百科-文章-老师",
		component: () => import('@/views/teacher/encyclopaedia/encyclopaedia_article.vue')
	},
	{
		path: "/premium_courses",
		name: "精品课程-老师",
		component: () => import('@/views/teacher/premiumCourses/coursesList.vue')
	},
	{
		path: "/courseVideo",
		name: "精品课程-视频-老师",
		component: () => import('@/views/teacher/premiumCourses/courseVideo.vue')
	},
	{
		path: "/courseTask",
		name: "精品课程-课程任务-老师",
		component: () => import('@/views/teacher/premiumCourses/courseTask.vue')
	},
	{
		path: "/index_student_list_teacher",
		name: "学生列表-老师",
		component: () => import('@/views/teacher/index_student_list.vue')
	},
	// 评估预警
	{
		path:"/singleCale",
		name:"评估预警-单量表预警",
		component:()=>import('@/views/teacher/assessment/singleCale.vue')
	  },
	  {
		path:"/modelWarning",
		name:"评估预警-模型预警",
		component:()=>import('@/views/teacher/assessment/modelWarning.vue')
	  },
	  {
		path:"/dailyEvaluation",
		name:"评估预警-日常访谈评估",
		component:()=>import('@/views/teacher/assessment/dailyEvaluation.vue')
	  },
	//心理热线
	{
		path:"/Psychological",
		name:"心理热线-通话管理",
		component:()=>import('@/views/teacher/Psychological/callManagement.vue')
	  },
	  {
		path:"/online",
		name:"心理热线-热线咨询管理",
		component:()=>import('@/views/teacher/Psychological/onlineConsult.vue')
	  },
	  {
		path:"/Crisisreport",
		name:"心理热线-危机上报管理",
		component:()=>import('@/views/teacher/Psychological/Crisisreport.vue')
	  },
	  {
		path:"/consulterMsg",
		name:"心理热线-咨询师个人中心",
		component:()=>import('@/views/teacher/Psychological/consulterMsg.vue')
	  },
	  {
	  	path: "/message_center_teacher",
	  	name: "消息中心-教师",
	  	component: () => import('@/views/teacher/message_center.vue')
	  },
	  {
	  	path: "/time",
	  	name: "时间",
	  	component: () => import('@/views/teacher/time.vue')
	  },
    //------------- zfp--end-------------
]

let router = new VueRouter({
	routes,
	mode: "hash",
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0
			}
		}
	}
});
router.beforeEach((to, form, next) => {
	console.log("要跳转的页面", to);
	const token = localStorage.getItem("token");
	const userType = localStorage.getItem("userType");
	const loginOverdue = localStorage.getItem('loginOverdue')
	if (!token && to.path != "/") {
		Message({
			message: '登录失效'
		})
		next("/");
		return
	} else if (token && to.path == "/" && loginOverdue == 'no') {
		if (userType == 'TEACHER') {
			next({path:"/teacheHome"});
			return
		} else if(userType == 'PARENT') {
			next({path:"/homeParents"});
			return
		} else if (userType == 'STUDENT') {
			next({path:"/Indexstudents"});
			return
		}
	} else if (token && to.path == "/" && loginOverdue == 'yes') {
		next("/");
		return
	}
	next()
})

export default router