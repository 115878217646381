<template>
  <div id="app">
    
    <router-view/>
  </div>
</template>

<script>

	import "@/assets/font/Medium.ttf"
	export default{}
</script>

<style>
@font-face {
	font-family: "Medium";
	src: url(~@/assets/font/Medium.ttf) format("truetype");
}
body {
	font-family: "Medium"
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; 
}
.pageWidth{
  width: 62.5vw;
  margin: 0 auto;
}

*{
	margin: 0;
	padding: 0;
  
}
body{
  font-family: PingFangSC;
  /* word-break: break-word; */
  width:100%;
}
a{
	text-decoration: none;	
}
div{
	box-sizing: border-box;
}
ul{
	list-style-type: none;
}
.all_center{
	min-height: 1080px;
}

</style>
