import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import htmlToPdf from "../src/utils/htmlToPdf"
Vue.use(htmlToPdf)

Vue.config.productionTip = false

  // moment插件
import moment from 'moment'
moment.locale('zh-cn');
Vue.prototype.$moment = moment

// element插件
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

// import Axios from 'axios'
// Vue.prototype.$axios=Axios
// localStorage.setItem("tenant","MDAwMA==");

import get_date_ago from "./utils/dateFormmat.js";
// console.log(get_date_ago)
Vue.prototype.$get_date_ago=get_date_ago;

import DropMenuOption from "@/components/DropMenuOption.vue";
Vue.component("DropMenuOption",DropMenuOption);



import $request from "@/api/request";
Vue.prototype.$request=$request;

import pageNav from './components/pageNav'
Vue.component("pageNav",pageNav)

import bottomNav from './components/bottomNav'
Vue.component("bottomNav",bottomNav)

// 创建eventBus
Vue.prototype.$EventBus = new Vue();

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
